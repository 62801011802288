@import "./fonts.scss";


td,th{
    font-family:"$barlow";
}

h1,h2,h3{
    font-family: "$robo-bold";
}

h4,h5,h6,p,a{
    font-family:"$barlow";
}