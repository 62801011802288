* {
  box-sizing: border-box;
}

body {
  margin: 5em;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
  height: 100%;
  overflow: hidden;
}

tbody tr:hover,
tbody tr:nth-of-type(odd):hover {
  background-color: var(--primary-table-hover) !important;
}

.danger-button {
  background-color: var(--primary-danger) !important;
  border: none !important;
  color: white !important;
}

.danger-button-outlined {
  border: 1px solid var(--primary-danger) !important;
  background-color: white !important;
  color: var(--primary-danger);
}

.dropdown-menu-custom {
  transform: none !important;
  padding: 10px 10px !important;
  font-family: '$barlow' !important;
}

.dropdown-menu-custom>.dropdown-item {
  padding: 5px 10px !important;
  cursor: pointer !important;
}

.dropdown-menu-custom>.dropdown-item:hover {
  padding: 5px 10px !important;
  border-radius: 5px;
  background-color: #c4c4c4 !important;
}

.info {
  font-family: '$barlow' !important;
}

.bs-popover-end>.popover-arrow::before {
  border-right-color: var(--primary-light-color-v2) !important;
}

.bs-popover-top>.popover-arrow::before {
  border-top-color: var(--primary-light-color-v2) !important;
}

.bs-popover-bottom>.popover-arrow::before {
  border-bottom-color: var(--primary-light-color-v2) !important;
  /* margin-right: 40px; */
}

.bs-popover-start>.popover-arrow::before {
  border-left-color: var(--primary-light-color-v2) !important;
}

.sidebar-mini.sidebar-collapse .offcanvas-bottom {
  width: calc(100% - 60px);
  position: absolute;
  left: 61px !important;
  height: calc(100% - 109px);
}

.rdrStartEdge {
  background-color: #ff833b !important;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.rdrInRange {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.rdrDayStartPreview,
.rdrDayEndPreview {
  border-radius: 0px !important;
  /* border: 1px solid black !important; */
}

.rdrMonthName {
  position: absolute;
  top: 15px;
  margin-left: 120px;
  font-size: 13px;
  color: #000 !important;
}

.rdrWeekDay {
  color: #000 !important;
  font-weight: 700 !important;
  font-size: 13px !important;
}

.rdrMonthAndYearPickers {
  display: none !important;
}

.rdrPprevButton i {
  /* transform: translate(-3px, 0px) !important; */
  height: 8px !important;
  width: 8px !important;
  border: solid black !important;
  border-width: 0 2px 2px 0 !important;
  -webkit-transform: rotate(135deg) !important;
}

.rdrNextButton i {
  transform: translate(-3px, 0px) !important;
  height: 8px !important;
  width: 8px !important;
  border: solid black !important;
  border-width: 0 2px 2px 0 !important;
  -webkit-transform: rotate(313deg) !important;
  margin-left: 11px !important;
}

.rdrNextPrevButton {
  width: 35px !important;
  height: 33px !important;
  background-color: #fff !important;
  transition: all 0.2s !important;
  /* display: flex !important;
    justify-content: center !important;
    align-items: center !important; */
  /* margin: 0 !important; */
}

.rdrNextPrevButton:hover {
  background-color: #eee !important;
}

.rdrStaticRange:nth-child(1) {
  display: none !important;
}

.rdrStaticRange:nth-child(2) {
  display: none !important;
}

.rdrStaticRange:nth-child(3) {
  display: none !important;
}

.rdrStaticRange:nth-child(4) {
  display: none !important;
}

.rdrStaticRange:nth-child(7) {
  display: block !important;
  position: relative !important;
}

.rdrStaticRange:nth-child(7)::before {
  content: 'Custom range' !important;
  position: absolute !important;
}

.rdrStaticRange {
  border-bottom: 0px !important;
}

.rdrDayNumber span {
  color: #000 !important;
}

.rdrEndEdge .rdrDayNumber span {
  color: #fff !important;
}

.rdrEndEdge {
  background-color: #ff833b !important;
  border-radius: 0px !important;
}

.rdrStaticRangeLabel:focus {
  color: #fff !important;
  background-color: #ff833b !important;
}

.dropend .dropdown-toggle::after {
  width: 100%;
  border: 0.3em solid transparent !important;
  background-color: transparent !important;
}

.dropend:hover,
.dropend:focus {
  background-color: var(--primary-dropColor) !important;
  border: none !important;
}

.test123 {
  min-height: 500px !important;
}

.fr-sticky-on {
  position: absolute !important;
}

.fr-view img.fr-dib {
  margin: 0 !important;
}

.fr-view table td {
  border-bottom: 1px solid var(--primary-black) !important;
  background-color: var(--primary-white);
  padding: 10px !important;
}

.fr-view table th {
  padding: 10px !important;
}

.fr-view table td,
.fr-view table th {
  border: 0.5px solid var(--primary-black) !important;
}

.investor_T_body {
  background-color: transparent !important;
}

.investorRemoveColor {
  background-color: transparent !important;
}

.FinancialSection_0,
.FinancialSection_1,
.FinancialSection_2,
.FinancialSection_3,
.FinancialSection_4 {
  overflow: auto;
}

@media (min-width: 1279px) {
  .abs_dounutInnerDiv {
    left: 11.3% !important;
  }
}

@media (min-width: 1422px) {
  .abs_dounutInnerDiv {
    left: 10.5% !important;
  }
}

@media (min-width: 1599px) {
  .abs_dounutInnerDiv {
    left: 9.5% !important;
  }
}

.removeNotificationDot {
  display: none !important;
}

.removeNotificationDotReports {
  display: none !important;
}

.notifyReportClassname {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  left: 13px;
  top: -2px;
}

.notifyMatrixClass {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  left: 13px;
  top: -2px;
}

.notifyBattleClass {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  left: 13px;
  top: -2px;
}

.grip-flex {
  width: 100% !important;
}

.tableWidthClassname {
  width: 100%;
}

.jodit-status-bar {
  display: none !important;
}